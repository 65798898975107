<template>
  <div class="page-container">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-boody" v-if="configure">
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">开发商划转租金配置信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">所在省</span>
                      <span class="descriptions-item-content">{{ configure.province }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开发商ID</span>
                      <span class="descriptions-item-content">{{ configure.developersId }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开发商名称</span>
                      <span class="descriptions-item-content">{{ configure.developers?configure.developers.name:"" }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">租金价格</span>
                      <span class="descriptions-item-content">
                        &yen;{{
                          configure.type == 0 ? (configure.rent / 100).toFixed(2) : (configure.rent1 / 100).toFixed(2) + ',' + (configure.rent2 / 100).toFixed(2)
                        }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">租金类型</span>
                      <span class="descriptions-item-content">
                        {{ ['通用型', '10+15型', '5+20型'][configure.type] }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">有效期</span>
                      <span class="descriptions-item-content">
                        {{ configure.startDate + '-' + configure.endDate }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">有效状态</span>
                      <span class="descriptions-item-content">
                        {{ configure.state ? "失效" : "有效" }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">创建时间</span>
                      <span class="descriptions-item-content">
                        {{ configure.time }}
                      </span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {configureInfo} from "@/api/common"

export default {
  name: "DeveloperConfigureInfo",
  data() {
    return {
      id: null,
      configure: null,
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getConfigureInfo()
  },
  methods: {
    getConfigureInfo() {
      let that = this
      configureInfo(that.id).then(res => {
        that.configure = res
      })
    }
  }
}
</script>

<style scoped>

</style>
